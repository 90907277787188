// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import type { StyleProps, } from '@haaretz/htz-css-tools';
import AudioPlayerWithActions from '../AudioPlayerWithActions/AudioPlayerWithActions';
import {
  AudioProgressController,
  PlayButton,
  SkipButton,
  PlaybackRateButton,
  MuteButton,
  AudioElapsedTime,
  AudioDuration,
} from '../HtzAudioPlayer';
import AudioArticleBodyHeader from '../ArticleBodyAudio/ArticleBodyAudioHeader';
import ArticleBodyAudioRss from '../ArticleBodyAudio/ArticleBodyAudioRss';
import useWebViewChecker from '../../hooks/useWebViewChecker';
import ArticleBodyAudioWebViewHDC from './ArticleBodyAudioWebViewHDC';
import type { ImageDataType, } from '../../flowTypes/ImageDataType';

const wrapperGridAreas = [
  {
    until: 's',
    value: `
"he"
"progress"
"controls"
"rss"`,
  },
  {
    from: 's',
    value: `
"he       rss"
"progress controls"`,
  },
];

const wrapperMiscStyles = ({ theme, miscStyles, }) => ({
  direction: 'unset',
  backgroundColor: theme.color('primary', '-5'),
  color: theme.color('primary'),
  padding: [ { until: 's', value: '4rem', }, { from: 's', value: '2rem', }, ],
  display: 'grid',
  gridTemplateAreas: wrapperGridAreas,
  gridTemplateColumns: [ { from: 's', value: '1fr auto', }, ],
  gridColumnGap: [ { from: 's', value: '6rem', }, ],
  gridRowGap: [ { from: 's', value: '1rem', }, ],
  type: [ { until: 'xl', value: -1, }, { from: 'xl', value: -2, }, ],
  marginBlockEnd: '3rem',
  ...miscStyles,
});

const progressWrapperStyles = ({ theme, }) => ({
  gridArea: 'progress',
  alignSelf: 'end',
  display: 'grid',
  gridTemplateAreas: `
"progress progress progress"
"elapsed  .        duration"
`,
  gridTemplateColumns: 'auto 1fr auto',
  gridRowGap: '1rem',
  fontWeight: 700,
  ...theme.type(-3),
});

const sliderDiameter = [
  { until: 'xl', value: 'calc(2rem + 2px)', },
  { from: 'xl', value: '2rem', },
];

const controlsWrapperStyles = ({ theme, }) => ({
  gridArea: 'controls',
  display: 'grid',
  gridColumnGap: '2.5rem',
  extend: [
    theme.mq({ until: 's', }, {
      gridTemplateAreas: '"rate skipBack play skipFro mute"',
      // setting fixed column width so that layout won't shift when playback rate changes
      gridTemplateColumns: '4rem 4rem auto 4rem 4rem',
      justifyContent: 'space-between',
      alignItems: 'center',
    }),
    theme.mq({ from: 's', }, {
      gridTemplateAreas: `
"skipBack play skipFro"
"rate   play mute"`,
      // setting fixed column width so that layout won't shift when playback rate changes
      gridTemplateColumns: '4rem max-content 4rem',
    }),
    theme.mq({ until: 'xl', }, { gridRowGap: '2rem', }),
    theme.mq({ from: 'xl', }, { gridRowGap: '1rem', }),
  ],
});

const skipIconSize = [
  { until: 's', value: 3.5, },
  { from: 's', until: 'xl', value: 4.5, },
  { from: 'xl', value: 3.8, },
];
const skipTextAttrs = {
  fontSize: '10',
  y: '58%',
};

const playButtonDiameter = [
  { until: 's', value: '6rem', },
  { from: 's', until: 'xl', value: '7rem', },
  { from: 'xl', value: '6rem', },
];

const playButtonMiscStyles = {
  alignSelf: 'center',
  marginInlineStart: [ { until: 's', value: '1rem', }, ],
  marginInlineEnd: [ { until: 's', value: '1rem', }, ],
};

const playIconSize = [
  { until: 'xl', value: 3, },
  { from: 'xl', value: 18 / 7, },
];

const pauseBarHeight = [
  { until: 's', value: '2.5rem', },
  { from: 's', until: 'xl', value: '3rem', },
  { from: 'xl', value: `${18 / 7}rem`, },
];

const pauseBarWidth = '5px';

const playbackRateButtonMiscStyles = {
  type: [ { until: 'xl', value: -2, }, { from: 'xl', value: -3, }, ],
  lineHeight: '1 !important',
  minHeight: '3rem',
};

const muteButtonMiscStyles = {
  justifySelf: [ { until: 's', value: 'end', }, ],
  transform: [ { from: 'xl', value: 'translateY(2px)', }, ],
};

const muteIconSize = [ { until: 'xl', value: 3, }, { from: 'xl', value: 2.5, }, ];

type ArticleBodyAudioHdcProps = {
  fileUrl: string,
  contentName: string,
  caption: ?string,
  isOmny: ?boolean,
  channelLinks: ?{
    spotify: string,
    google: string,
    apple: string,
  },
  miscStyles: ?StyleProps,
  mobileTitle?: string,
  channelLabel?: string,
  image?: ImageDataType,
};

function ArticleBodyAudioHdc(props: ArticleBodyAudioHdcProps) {
  const { css, theme, } = useFela();
  const isWebView = useWebViewChecker();

  if (isWebView) {
    return <ArticleBodyAudioWebViewHDC {...props} />;
  }

  const {
    fileUrl,
    contentName,
    caption,
    channelLinks,
    isOmny,
    miscStyles,
  } = props;

  return (
    <AudioPlayerWithActions
      fileUrl={fileUrl}
      contentName={contentName}
      isOmny={isOmny}
      miscStyles={wrapperMiscStyles({ theme, miscStyles, })}
    >
      <AudioArticleBodyHeader
        contentName={contentName}
        caption={caption}
      />
      {channelLinks ? (
        <ArticleBodyAudioRss
          channelLinks={channelLinks}
          miscStyles={{
            marginTop: [ { until: 's', value: '2rem', }, ],
          }}
        />
      ) : null}
      <div className={css(progressWrapperStyles)}>
        <AudioProgressController
          railColor="primary"
          progressColor={[ 'audioPlayerArticleBody', 'header', ]}
          sliderDiameter={sliderDiameter}
        />
        <AudioElapsedTime />
        <AudioDuration />
      </div>
      <div className={css(controlsWrapperStyles)}>
        <SkipButton
          skipBy={-15}
          gridArea="skipBack"
          size={skipIconSize}
          textAttrs={skipTextAttrs}
        />
        <PlayButton
          gridArea="play"
          bgColor="primary"
          color={[ 'primary', '-5', ]}
          diameter={playButtonDiameter}
          playIconSize={playIconSize}
          pauseBarHeight={pauseBarHeight}
          pauseBarWidth={pauseBarWidth}
          miscStyles={playButtonMiscStyles}
        />
        <SkipButton
          skipBy={15}
          gridArea="skipFro"
          size={skipIconSize}
          textAttrs={skipTextAttrs}
        />
        <PlaybackRateButton
          timesSymbol="X"
          miscStyles={playbackRateButtonMiscStyles}
          alignItems={[ { until: 's', value: 'center', }, { from: 's', value: 'end', }, ]}
          justifyContent={[ { until: 's', value: 'start', }, { from: 's', value: 'center', }, ]}
        />
        <MuteButton
          size={muteIconSize}
          miscStyles={muteButtonMiscStyles}
        />
      </div>
    </AudioPlayerWithActions>
  );
}

ArticleBodyAudioHdc.defaultProps = {
  isOmny: false,
  caption: null,
  channelLinks: null,
  miscStyles: null,
  mobileTitle: null,
  channelLabel: null,
  image: null,
};

export default ArticleBodyAudioHdc;
