// @flow
import * as React from 'react';
import { ZOIDBERG_BIG_LIST_QUERY, } from '@haaretz/graphql';

import ZoidbergBigView from './ZoidbergBigView.js';
import ListWrapper from '../../ListWrapper';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import useMedia from '../../../../hooks/useMedia';

type Props = {
  updateListDuplication: Function,
  variables: { offset: number, },
  listData: ListDataType,
  viewProps: { forceView?: boolean, },
};

export default function ZoidbergBig(props: Props): React.Node {
  return (
    <ListWrapper
      {...props}
      query={ZOIDBERG_BIG_LIST_QUERY}
      view="ZoidbergBig"
    >
      {dataProps => <ZoidbergBigView {...dataProps} />}
    </ListWrapper>
  );
}
