import React from 'react';
import { useFela, } from 'react-fela';
import dynamic from 'next/dynamic';
import {
  // General Elements
  ArticleBody,
  Breadcrumbs,
  ChangeableElementGroup,
  ClickTrackerWrapper,
  BreakingNewsBox,
  Debug,
  ElementGroup,
  Embed,
  Enhancement,
  Error,
  HdcFooter,
  AdSlot,
  AdSlotPrioritySelector,
  GraphController,
  GridContainer,
  GridElement,
  HtmlElement,
  Image,
  ImageGallery,
  Interactive,
  LinksBlock,
  HtzLink,
  HtmlNode,
  List,
  MagazineRecipes,
  Masthead,
  MobileListWrapper,
  NextUp,
  Outbrain,
  Paragraph,
  PromotionalCardElement,
  Quote,
  RelatedArticles,
  RssFeed,
  SeriesArticles,
  SpecialPromotions,
  PodcastEpisodeHDC,
  TableScore,
  Tags,
  TopNews,
  Video,
  withDfpSideEffect,
  WrappedComments,

  // List views
  Bender,
  BenderBig,
  Butthead,
  Farnsworth,
  Leela,
  ZoidbergLabels,
  ZoidbergBig,
  ZoidbergEng,
  Clampazzo,
  Marco,
  Michelle,

  // icons
  IconAlefLogo,
  IconHaaretzComLogoFull,
  IconHaaretzComLabel,
  // IconHaaretzComLogo,
  IconAlefLogoTransparent,
  IconHtzLoader,
  // utils
  getElementsFactory,
  ArticlesReadCounter,
} from '@haaretz/htz-components';

const ArticleBodyAudio = dynamic(() => import('../components/ArticleBodyAudio/ArticleBodyAudio'));

const ArticleHeaderAudio = dynamic(() => import('../components/ArticleHeaderAudio/ArticleHeaderAudio'));

const fileUpload = dynamic(
  () => import('../components/FileUpload'),
  {
    ssr: false,
    loading: () => null,
  }
);

const MobileNewsletter = dynamic(() => import('../components/Newsletter/MobileNewsletter'), {
  loading: () => null,
  ssr: false,
});
const Newsletter = dynamic(() => import('../components/Newsletter/Newsletter'), {
  loading: () => null,
  ssr: false,
});
const ResponsiveNewsletter = dynamic(() => import('../components/Newsletter/ResponsiveNewsletter'), {
  loading: () => null,
  ssr: false,
});
const PersonalNewsletter = dynamic(() => import('../components/Newsletter/PersonalNewsletter'), {
  loading: () => null,
  ssr: false,
});

// Infographics
const VisualInfo = dynamic(
  () => import('../components/Infographics/VisualInfo'),
  { loading: () => null, }
);

const Questions = dynamic(
  () => import('../components/Infographics/Questions'),
  { loading: () => null, }
);

const Columns = dynamic(
  () => import('../components/Infographics/Columns'),
  { loading: () => null, }
);

const Timeline = dynamic(
  () => import('../components/Infographics/Timeline'),
  { loading: () => null, }
);

const Ids = dynamic(
  () => import('../components/Infographics/Ids'),
  { loading: () => null, }
);

const InfographicList = dynamic(
  () => import('../components/Infographics/InfographicList'),
  { loading: () => null, }
);

const ImageGalleryTeaser = dynamic(() => import('../components/ImageGalleryTeaser/ImageGalleryteaser'));

// const tagsFromTagElement = ({ tagsList, }) => tagsList.map(x => x.contentName);

// eslint-disable-next-line react/prop-types
function MastheadLogo({ miscStyles, themeColor, }) {
  const { theme, } = useFela();
  return (
    <IconHaaretzComLogoFull
      attrs={{ 'aria-hidden': true, }}
      size={theme.mastheadStyle.articlePageLogoSize}
      color={[ themeColor, 'mastheadLogo', ]}
      secondaryColor={theme.color('secondary')}
      miscStyles={{
        display: 'block',
        marginTop: [ { until: 's', value: '1rem', }, ],
        marginBottom: [ { until: 's', value: '1rem', }, ],
      }}
    />
  );
}

// eslint-disable-next-line react/prop-types
function MastheadLabelLogo() {
  const { theme, } = useFela();
  return (
    <IconHaaretzComLabel
      attrs={{ 'aria-hidden': true, }}
      size={4.5}
      fill={[ 'neutral', 'base', ]}
      miscStyles={{
        display: 'block',
        marginTop: [ { until: 's', value: '1rem', }, ],
        marginBottom: [ { until: 's', value: '1rem', }, ],
      }}
    />
  );
}

const elements = new Map([
  [ 'HtmlNode', HtmlNode, ],
  [ 'Enhancement', Enhancement, ],
  /* HeaderSlots components */
  [ 'PageNavigation', Masthead, ],
  // [ 'com.tm.PageTitle', Breadcrumbs, ],

  /* MainSlot components */
  // [ 'com.htz.StandardArticle', ArticleBody, ],
  [ 'Outbrain', Outbrain, ],
  [ 'CommentsPlaceHolder', WrappedComments, ],
  [ 'ClickTrackerWrapper', ClickTrackerWrapper, ],

  /* FooterSlot components */
  [ 'PageFooter', HdcFooter, ],

  /* ArticleBody components */
  [ 'CardRichTextElement', PromotionalCardElement, ],
  [ 'EmbedRichTextElement', Embed, ],
  [ 'Embed', Embed, ],
  [ 'FileElementRichTextElement', fileUpload, ],
  [ 'interactive', Interactive, ],
  [ 'InteractiveRichTextElement', Interactive, ],
  // [ 'com.polobase.audioEmbed', ArticleBodyAudio, ],
  // [ 'articleNarration', ArticleBodyAudio, ],
  [ 'articleHeaderAudio', ArticleBodyAudio, ],
  [ 'QuoteRichTextElement', Quote, ],
  [ 'linksBlock', LinksBlock, ],
  [ 'core_link_Link', HtzLink, ],
  // [ 'com.polobase.quickNewsletterRegistration', MobileNewsletter, ],
  [ 'RelatedArticles', RelatedArticles, ],
  [ 'SeriesArticles', SeriesArticles, ],
  [ 'PodcastEpisode', PodcastEpisodeHDC, ],
  [ 'htz_link_Link', SpecialPromotions, ],
  // [ 'com.polobase.MemberEventElement', ArticlesReadCounter, ],

  /*
   * This is only a temporary solution to avoid hitting preformance, and SHOULD BE AVOIDED as much as possible.
   * This creates a coupling between different concerns: (rendring components, accessing data)
   * but in this case as tags are deeply nested inside an array we use this to avoid searching them inside the entire page data
   * TODO: expose just the tags in graphql, then replace this temprary solution with one that fetchs the data from graphql
   */
  // [
  //   'tagsElement',
  //   withDfpSideEffect(Tags, {
  //     sideEffect: tagsElement => dfpTargeting.setTags(tagsFromTagElement(tagsElement)),
  //   }),
  // ],
  // [
  //   'com.tm.Graph',
  //   // eslint-disable-next-line react/prop-types
  //   ({ properties, ...props }) => (properties && properties.assetIds && properties.assetIds[0] ? (
  //   // eslint-disable-next-line react/prop-types
  //     <ArticleGraphController assetId={properties.assetIds[0]} {...props} />
  //   ) : null),
  // ],

  /* Misc components */
  [ 'GridItem', GridElement, ],
  [ 'Grid', GridContainer, ],
  [ 'image', Image, ],
  [ 'imageGalleryTeaser', ImageGalleryTeaser, ],
  [ 'infographic', Image, ],
  [ 'htz_image_Image', Image, ],
  // [ 'com.tm.BlogImage', Image, ],
  // [ 'com.tm.ImageGalleryElement', ImageGallery, ],
  // [ 'gallery', ImageGallery, ],
  // [ 'video', Video, ],
  [ 'HtmlElementRichTextElement', HtmlElement, ],
  [ 'HtmlElement', HtmlElement, ],
  // [ 'com.tm.newsLetterQuickRegistrationRespAuto', ResponsiveNewsletter, ],
  [ 'RegistrationRichTextElement', Newsletter, ],
  // [ 'com.tm.newsLetterPersonal', PersonalNewsletter, ],
  // [
  //   'com.tm.ListElement',
  //   () => (
  //     <Debug>
  //       <p>Old list, NOT SUPPORTED</p>
  //     </Debug>
  //   ),
  // ],
  [ 'htz_list_List', List, ],
  [ 'htz_link_Link', NextUp, ],
  // [ 'com.polobase.whtzMobileSiteListsWrapper', MobileListWrapper, ],
  [ 'SideBox', ChangeableElementGroup, ],
  [ 'HeaderNewsList', props => {
    if (props.kind === 'breakingNews') return <BreakingNewsBox {...props} />;
    return <TopNews {...props} />;
  }, ],
  [ 'topNews', TopNews, ],
  // [ 'com.tm.ElementGroup', ElementGroup, ],
  [ 'AdSlot', AdSlot, ],
  [ 'AdSlotPrioritySelector', AdSlotPrioritySelector, ],
  [ 'AdSlotRichTextElement', AdSlot, ],
  [ 'TenRecipes', MagazineRecipes, ],
  [ 'TableScore', TableScore, ],
  [ 'OnlifeFeed', RssFeed, ],
  // [ 'error', Error, ],
  [ 'GalleryRichTextElement', ImageGallery, ],


  [ null, null, ],

  // List views
  [ 'Bender', Bender, ],
  [ 'BenderBig', BenderBig, ],
  [ 'Farnsworth', Farnsworth, ],
  [ 'Leela', Leela, ],
  [ 'Zoidberg', ZoidbergBig, ],
  [ 'ZoidbergBig', ZoidbergBig, ],
  [ 'ZoidbergLabels', ZoidbergLabels, ],
  [ 'Clampazzo', Clampazzo, ],
  [ 'Marco', Marco, ],
  [ 'Michelle', Michelle, ],
  //   [ 'Spot', Spot, ],

  [ 'VisualInfo', VisualInfo, ],
  [ 'QAndA', Questions, ],
  [ 'ColumnsModel', Columns, ],
  [ 'Timeline', Timeline, ],
  [ 'Ids', Ids, ],
  [ 'ListInfo', InfographicList, ],

  // Icons
  [ 'articleHeaderMetaLogo', IconAlefLogo, ],
  [ 'mastheadLogo', MastheadLogo, ],
  [ 'mastheadLabelLogo', MastheadLabelLogo, ],
  [ 'siteLogo', IconHaaretzComLogoFull, ],
  [ 'siteIconTransparent', IconAlefLogoTransparent, ],
  [ 'loaderIcon', IconHtzLoader, ],
]);

const getArticleElements = getElementsFactory(elements);

export default getArticleElements;
