// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import { borderBottom, } from '@haaretz/htz-css-tools';

import type { ListDataType, } from '../../../../flowTypes/ListDataType';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';
import type { ListBiActionType, } from '../../../../flowTypes/ListBiActionType';
import type { ListBiImpressionType, } from '../../../../flowTypes/ListBiImpressionType';

import ListView from '../../../ListView/NewListView';
import Image from '../../../Image/Image';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import getImageAssets from '../../../../utils/getImageAssets';
import setBiAction from '../../../../utils/setBiAction';
import setBiImpression from '../../../../utils/setBiImpression';
import H from '../../../AutoLevels/H';
import ListItem from '../../elements/ListItem';
import { isAdSlot, } from '../../../../utils/validateType';
import AdSlot from '../../../AdManager/AdSlot';
import Section from '../../../AutoLevels/Section';
import Outbrain from '../../../Outbrain/Outbrain';
import useDarkModeChecker from '../../../../hooks/useDarkModeChecker';

type Props = {
  isLazyloadImages: boolean,
  list: ListDataType,
  biAction: ?ListBiActionType,
  biImpression: ?ListBiImpressionType,
};

ZoidbergBig.defaultProps = {
  isLazyloadImages: false,
};

export default function ZoidbergBig({
  list,
  isLazyloadImages,
  biAction,
  biImpression, }: Props): React.Node {
  const { theme, css, } = useFela();
  const { items, title, banners, } = list || {};
  const isDarkMode = useDarkModeChecker();

  const { adSlots, outbrain, } = banners || {};

  if (!items || !items.length) return null;
  const backgroundColor = isDarkMode ? 'transparent' : 'white';
  return (
    <div className={css({ display: 'flex', flexDirection: 'column', maxWidth: '300px', backgroundColor, })} data-test="zoidbergBig">
      <H
        className={css({
          fontWeight: '700',
          color: theme.color('zoidbergList', 'title'),
          marginBottom: '2rem',
          extend: [
            theme.type(1),
            borderBottom('2px', 1, 'solid', theme.color('zoidbergList', 'title')),
          ],
        })}
      >
        {title}
      </H>
      <ListView
        areasTemplate={`
    "t1 t1"
    "t2 t3"

    `}
        marginTop={0}
        // disableWrapper
        // keepHeadingLevel
        colGap="2.5rem"
        rowGap="2.5rem"
        gridGap={null}
        colTemplate="1fr 1fr"
        outerBackgroundColor="transparent"
        padding={null}
      >
        <Section isFragment>
          {items.slice(0, 3).map((item, i) => (
            <ZoidbergBigTeaser
              backgroundColor={backgroundColor}
              gridArea={`t${i + 1}`}
              key={item.contentId}
              isLazyloadImages={isLazyloadImages}
              item={item}
              biAction={setBiAction(i, item, biAction)}
              biImpression={setBiImpression(i, item, biImpression)}
              theme={theme}
            />
          ))}
        </Section>
      </ListView>
      {adSlots && adSlots.length > 0
        ? adSlots.map(item => (
          <ListItem key={item.contentId}>
            {isAdSlot(item) ? (
              <AdSlot
                wrapperMiscStyles={{
                  alignItems: 'flex-start',
                  display: 'flex',
                  marginTop: '2.5rem',
                }}
                {...item}
              />
            ) : null}
          </ListItem>
        ))
        : null}
      {outbrain ? (
        <div className={css({ marginTop: '2rem', })}>
          <Outbrain {...outbrain} />
        </div>
      ) : null}
    </div>
  );
}

type ZoidbergBigTeaserPropTypes = {
  gridArea: string,
  item: TeaserDataType,
  isLazyloadImages: boolean,
  theme: Object,
  biAction: ?() => void,
  biImpression: ?() => void,
  backgroundColor: 'white' | 'transparent',
};

const areasStackedTeaser = `
"media"
"content"
`;

ZoidbergBigTeaser.defaultProps = { isLazyloadImages: false, backgroundColor: 'white', };
function ZoidbergBigTeaser({
  gridArea,
  item,
  isLazyloadImages,
  biAction,
  biImpression,
  theme,
  backgroundColor,
}: ZoidbergBigTeaserPropTypes): React.Node {
  const [ isImpressionSent, setIsImpressionSent, ] = React.useState(false);
  const [ ref, inView, ] = useInView({ threshold: 0.3, triggerOnce: true, });

  React.useEffect(() => {
    if (inView && item && !isImpressionSent && biImpression) {
      biImpression();
      setIsImpressionSent(true);
    }
  }, [ biImpression, inView, isImpressionSent, item, ]);
  const isT1 = gridArea === 't1';

  const image = item?.mobileImage || item?.image;

  return (
    <Teaser
      backgroundColor={backgroundColor}
      gridArea={gridArea}
      areasTemplate={areasStackedTeaser}
      rowTemplate="auto 1fr"
      rowGap="1.5rem"
      gridGap={null}
      onClick={biAction}
      miscStyles={{ fontFamily: theme.fontStacks.alt, }}
      fwRef={ref}
    >
      <TeaserMedia data={item} onClick={biAction}>
        <Image
          lazyLoad={isLazyloadImages}
          image={image}
          imgOptions={getImageAssets({
            bps: theme.bps,

            aspect: isT1 ? 'headline' : 'regular',
            sizes: [
              { from: 'xl', size: '290', },
              { from: 'l', size: '260px', },
              { from: 'm', size: '348px', },
              { from: 's', size: '264px', },
              { size: 'calc(50vw - 36px)', },
            ],
            widths: [ 170, 200, 240, 265, 280, 315, 350, 600, ],
          })}
        />
      </TeaserMedia>
      <TeaserHeader
        isGridItem
        {...item}
        typeScale={[ { until: 'xl', value: -1, }, { from: 'xl', value: -2, }, ]}
        onClick={biAction}
      />
    </Teaser>
  );
}
