// @flow
import React from 'react';
import { useFela, } from 'react-fela';
import { useRouter, } from 'next/router';
import { useSlotData, } from '@haaretz/htz-components';
import { breakUrl, } from '@haaretz/app-utils';
import NoSSR from '../NoSSR/NoSSR';
import { stylesPropType, } from '../../propTypes/stylesPropType';
import IconBack from '../Icon/icons/IconBack';
import useArticleType from '../../hooks/Page/useArticleType';

import H from '../AutoLevels/H';
import Section from '../AutoLevels/Section';
import BlockLink from '../BlockLink/BlockLink';
import HtzLink from '../HtzLink/HtzLink';
import SpecialPromotions from '../SpecialPromotions/SpecialPromotions';

import EventTracker from '../../utils/EventTracker';
import isQueryValueTrue from '../../utils/isQueryValueTrue';

type Props = {
  contentId: string,
  contentName: string,
  isNextUp?: boolean,
  href: string,
  toolTip: string,
  kicker: string,
  linkText: string,
  /**
   * A special property holding miscellaneous CSS values that
   * trump all default values. Processed by
   * [`parseStyleProps`](https://Haaretz.github.io/htz-frontend/htz-css-tools#parsestyleprops)
   */
  miscStyles: stylesPropType,
};
NextUp.defaultProps = {
  isNextUp: false,
  kicker: 'Next Up',
  linkText: 'Read',
  miscStyles: null,
};

function nextUpButtonStyle({ theme, }) {
  return {
    display: 'inline',
    fontWeight: 700,
    // whiteSpace: 'nowrap',
    backgroundColor: theme.color('secondary'),
    borderColor: theme.color('secondary', 'base'),
    borderStyle: 'solid',
    color: theme.color('bg', 'base'),
    padding: '1',
  };
}
function wrapperStyle({ theme, }) {
  return {
    extend: [
      theme.mq({ until: 's', }, { marginTop: '0.4em', marginRight: '2.56rem', }),
    ],
  };
}

function titleStyle({ theme, }) {
  return {
    fontWeight: 400,
    backgroundColor: theme.color('nextUp', 'titleBg'),
    borderColor: theme.color('nextUp', 'titleBg'),
    borderStyle: 'solid',
    color: theme.color('white'),
    display: 'inline',
    boxDecorationBreak: 'clone',
    padding: '0.56rem 2rem',
  };
}

function hideUntilS({ theme, }) {
  return {
    extend: [
      theme.mq({ until: 's', }, { display: 'none', }),
    ],
  };
}

function hideFromS({ theme, }) {
  return {
    extend: [
      theme.mq({ from: 's', }, { display: 'none', }),
    ],
  };
}

const getArticleId = url => {
  if (!url) return null;

  const { path, } = breakUrl(url);
  const regex = /[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12}$/;
  return regex.test(path) ? path.match(regex)[0] : null;
};

function NextUp({ contentId, contentName, href, toolTip, kicker, linkText, miscStyles, }: Props) {
  const { css, theme, } = useFela({ miscStyles, });
  const router = useRouter();
  const articleType = useArticleType();
  const isMagazineArticle = articleType === 'magazineArticle';
  const postHeader = useSlotData('postHeader');
  const currentArticleId = getArticleId(router.query.path);
  const isNextUp = postHeader?.[2]?.style === 'next up' || false;

  const nextUpArticleId = getArticleId(href);
  const isSameArticle = currentArticleId === nextUpArticleId;
  const isGooglebot = isQueryValueTrue(router.query.isBot);

  const biActionData = {
    actionCode: 109,
    ArticleId: nextUpArticleId,
    ListId: contentId,
    NoInList: 1,
    ViewName: 'Next Up',
  };

  return isSameArticle || (isNextUp && isGooglebot)
    ? null
    : isNextUp
      ? (
        <NoSSR>
          <EventTracker>
            {({ biAction, }) => (
              <BlockLink
                href={href}
                onClick={() => biAction(biActionData)}
                miscStyles={{
                  ...(isMagazineArticle ? { backgroundColor: theme.color('nextUp', 'magazineBg'), } : {}),
                  fontStyle: 'italic',
                  display: [ { from: 's', value: 'none', }, ],
                  type: [ { from: 's', until: 'xl', value: 1, }, { from: 'xl', value: 0, }, ],
                  maxWidth: theme.articleStyle.body.maxWidth,
                  marginRight: 'auto',
                  marginLeft: 'auto',
                  paddingInlineStart: '2rem',
                  paddingInlineEnd: '2rem',
                  paddingTop: 'calc(2rem + 0.56rem)',
                  paddingBottom: 'calc(2rem + 0.56rem)',
                  lineHeight: [
                    { until: 's', value: '4.4rem', },
                    { from: 's', until: 'xl', value: '6rem', },
                    { from: 'xl', value: '5.3rem', },
                  ], }}
              >
                <span className={css(nextUpButtonStyle, hideFromS)}>
                  {theme.nextUpI18.kicker || ''}
                  {' '}
                  <IconBack size={3} miscStyles={{ transform: 'rotate(180deg)', }} />
                </span>
                <Section
                  tagName="div"
                  className={css(wrapperStyle)}
                >
                  <H className={css(titleStyle)}>
                    <HtzLink
                      href={href}
                      onClick={() => biAction(biActionData)}
                    >
                      <span className={css(hideUntilS)}>{`${kicker} // `}</span>
                      {contentName}
                    </HtzLink>
                  </H>
                  <span className={css(nextUpButtonStyle, hideUntilS)}>
                    {linkText}
                    <IconBack size={3} miscStyles={{ transform: 'rotate(180deg)', }} />
                  </span>
                </Section>
              </BlockLink>
            )}
          </EventTracker>
        </NoSSR>
      )
      : (
        <SpecialPromotions {...{ contentId, contentName, isNextUp, href, toolTip, kicker, linkText, miscStyles, }} />
      );
}

export default NextUp;
