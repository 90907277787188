// @flow
import type { StyleProps, } from '@haaretz/htz-css-tools';
import * as React from 'react';
import { useFela, createComponent, } from 'react-fela';
import {
  PlayButtonWebView,
} from '../HtzAudioPlayer';
import { getAudioLinkWebView, } from '../HtzAudioPlayer/audioPlayerUtils';
import type { ImageDataType, } from '../../flowTypes/ImageDataType';
import Image from '../Image/Image';
import getImageAssets from '../../utils/getImageAssets';
import checkSiteFromConfig from '../../utils/checkSiteFromConfig';

const { isHDC, } = checkSiteFromConfig();

const containerStyle = ({ theme, }) => ({
  marginBottom: '4rem',
  backgroundColor: theme.color('audioPlayerArticle', 'bgWebView'),
  display: 'grid',
  gridGap: '2rem',
  gridTemplateColumns: '8rem 1fr 60px',
  padding: '5rem 2rem',
  alignItems: 'center',
  fontFamily: theme.fontStacks.alt,
});

const Container = createComponent(containerStyle);

const textStyle = {
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 700,
};

const Text = createComponent(textStyle);

const titleStyle = ({ theme, }) => ({
  color: theme.color('primary'),
  fontWeight: isHDC ? 'bold' : '300',
  extend: [
    theme.type(-2),
  ],
});

const Title = createComponent(titleStyle);


const subTitleStyle = ({ theme, }) => ({
  fontWeight: 'bold',
  extend: [
    theme.type(-2),
  ],
});

const SubTitle = createComponent(subTitleStyle, 'span');


type Props = {
  fileUrl: string,
  contentName: string,
  channelLinks: ?{
    spotify: string,
    google: string,
    apple: string,
  },
  miscStyles: ?StyleProps,
  isHeadline: boolean,
  loadAfterFirstClick: boolean,
  mobileTitle?: string,
  channelLabel?: string,
  image?: ImageDataType,
  channelImage?: ImageDataType,
};

ArticleBodyAudioWebViewHDC.defaultProps = {
  fileUrl: null,
  contentName: null,
  channelLinks: null,
  miscStyles: null,
  isHeadline: false,
  loadAfterFirstClick: true,
  mobileTitle: null,
  channelLabel: null,
  image: null,
  channelImage: null,
};

function ArticleBodyAudioWebViewHDC({
  fileUrl,
  contentName,
  channelLinks,
  loadAfterFirstClick,
  miscStyles,
  isHeadline,
  mobileTitle,
  channelLabel,
  image,
  channelImage,
  ...props
}: Props) {
  const { theme, } = useFela();

  const href = getAudioLinkWebView({
    fileUrl,
    mobileTitle,
    channelLabel,
    channelLinks,
    image,
    channelImage,
  });

  return (
    <a href={href}>
      <Container>
        <PlayButtonWebView
          color={[ 'audioPlayerArticle', 'playBtnWebView', ]}
          bgColor={[ 'audioPlayerArticle', 'playBgWebView', ]}
          diameter="8rem"
          playIconSize={3}
          miscStyles={{
            zIndex: 1,
          }}
        />
        <Text>
          <Title>
            {isHDC ? mobileTitle : (
              <>
                {channelLabel}
                {' | '}
                <SubTitle>{contentName}</SubTitle>
              </>
            )}
          </Title>
        </Text>
        <Image
          image={image}
          imgOptions={getImageAssets({
            bps: theme.bps,
            aspect: 'square',
            sizes: [ { size: '60px', }, ],
            widths: [ 60, ],
          })}
          lazyLoad={false}
        />
      </Container>
    </a>
  );
}
export default ArticleBodyAudioWebViewHDC;
